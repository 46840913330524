<template>
  <div class="flex flex-wrap align-end mg-t-20 mg-b-50 uploader">
    <div v-for="(image, index) in images" class="uploader-img" :key="index">
      <img :src="valueKey ? image[valueKey] : image" class="uploader-img__img"/>
      <i class="fas fa-times" @click="deleteImage(index)"/>
    </div>
    <input ref="fileInput" type="file" accept="image/*" @change="imageSelected">
    <div class="btn btn--red" @click.prevent="openDialog"><i class="fas fa-plus-circle"></i> {{btnLabel || $t('global.add_image')}}</div>
    <div class="image-modal" v-if="showEdit">
      <div class="image-modal__cont">
        <div ref="imageCrop" class="image-modal__prev"/>
        <div class="flex justify-center align-center">
          <button class="btn btn--red w-100" @click="closeEdit">Cancel</button>
          <button class="btn btn--green mg-l-15 w-100" @click="saveImage">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Upload } from '@/api/api'
import Croppie from 'croppie'

export default {
  name: 'ImageSelect',
  props: [
    'defaultValue',
    'valueKey',
    'btnLabel',
    'multi'
  ],
  data () {
    return {
      isUploading: false,
      showEdit: false,
      images: []
    }
  },
  mounted () {
    this.images = [...this.defaultValue]
  },
  watch: {
    defaultValue (newVal) {
      this.images = [...newVal]
    }
  },
  methods: {
    closeEdit (e) {
      e.preventDefault()
      this.showEdit = false
    },
    saveImage (e) {
      e.preventDefault()
      this.vanilla.result('blob').then((blob) => {
        this.showEdit = false
        this.imageUpload(blob)
      })
    },
    async imageUpload (file) {
      this.isUploading = true

      try {
        const imageFile = file
        const resp = await Upload.image(imageFile)

        if (this.multi) {
          if (this.valueKey) {
            this.images.push({
              [this.valueKey]: resp.data
            })
          } else {
            this.images.push(resp.data)
          }
        } else {
          if (this.valueKey) {
            this.images.push({
              [this.valueKey]: resp.data
            })
            this.images[0] = {
              [this.valueKey]: resp.data
            }
          } else {
            this.images[0] = resp.data
          }
        }

        this.$emit('selected', {
          images: this.images
        })
      } catch (e) {
        this.$toast.error(this.$t('global.photo_up_fail'))
      } finally {
        this.isUploading = false
      }
    },
    async imageSelected (e) {
      const file = e.target.files[0]
      this.showEdit = true

      window.setTimeout(() => {
        const el = this.$refs.imageCrop
        this.vanilla = new Croppie(el, {
          viewport: { width: 500, height: 500 },
          boundary: { width: 650, height: 600 },
          enableOrientation: true
        })
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.vanilla.bind({
            url: reader.result,
            orientation: 1
          })
        }
        reader.onerror = () => {
          this.showEdit = false
        }
      }, 500)
    },
    deleteImage (index) {
      const images = [...this.images]

      images.splice(index, 1)

      this.$emit('selected', {
        images
      })
    },
    openDialog () {
      this.$refs.fileInput.value = ''
      this.$refs.fileInput.click()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
  @import "~croppie/croppie.css";
</style>
